<div class="logged-in-dashboard">
    <div class="flex-sidebar" [ngClass]="{'collapsed': opened()}">
        <button class="sidebar-toggler" mat-icon-button (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="user-metadata">
            <div class="user-metadata__image">
                <img *ngIf="(is_authenticated_user |async) && userImageUrl" [src]="userImageUrl">
                <span *ngIf="!(is_authenticated_user |async)|| !userImageUrl"
                    class="image-placeholder">{{displayName?.charAt(0)}}</span>
            </div>
            <p class="user-metadata__name">{{displayName}}</p>
            <p class="user-metadata__email">{{displayEmail}}</p>
        </div>
        <ul class="available-applications">
            <li *ngFor="let menu of menuData$ | async">
                <a *ngIf="hasAccessToMenuItem(menu.access_tokens)" class="available-app" [routerLink]="menu.url"
                    [routerLinkActive]="'active'"><span class="icon">{{menu.name.charAt(0)}}</span>
                    <p>{{menu.name}}</p>
                </a>
                <span *ngIf="menu.children" class="expand-icon" (click)="menu.isCollapsed = !menu.isCollapsed"><mat-icon
                        svgIcon="chevron-down-outline"></mat-icon></span>
                <div *ngIf="menu.children" [ngClass]="{'vertical-collapsed': menu.isCollapsed }"
                    class="level-one-submenu">
                    <div *ngFor="let submenu of menu.children">
                        <a class="level-one-submenu__item" [routerLink]="submenu.url"
                            [routerLinkActive]="'active'">{{submenu.name}}</a>
                        <span *ngIf="submenu.children" class="expand-icon"
                            (click)="submenu.isCollapsed = !submenu.isCollapsed"><mat-icon
                                svgIcon="chevron-down-outline"></mat-icon></span>
                        <div *ngIf="submenu.children" [ngClass]="{'vertical-collapsed': submenu.isCollapsed }"
                            class="level-two-submenu">
                            <div *ngFor="let subsubmenu of submenu.children">
                                <a class="level-two-submenu__item" [routerLink]="subsubmenu.url"
                                    [routerLinkActive]="'active'">{{subsubmenu.name}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="user-actions">
            <button mat-button (click)='signOut()'>
                <span class="ri-logout-box-r-line"></span>
                <span>Logout</span>
            </button>
        </div>
    </div>
    <div class="flex-content">
        <div class="loaded-application">
            <router-outlet (activate)="opened.set(true)"></router-outlet>
        </div>
    </div>
</div>