import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DcmViewerComponent } from '../dcm-viewer/dcm-viewer.component';

@Component({
  selector: 'app-viewer-wrapper',
  templateUrl: './viewer-wrapper.component.html',
  styleUrls: ['./viewer-wrapper.component.scss'],
  standalone: true,
  imports: [DcmViewerComponent]
})
export class ViewerWrapperComponent implements OnInit {

  public sessionId: string;
  constructor(private staticRoute: ActivatedRoute) {
    this.sessionId = this.staticRoute.snapshot.params.sessionId;
  }

  ngOnInit(): void {
  }

}
