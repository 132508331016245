import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, WritableSignal, signal } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FirebaseAuthService, ViewCommunicationService } from 'src/app/modules/shared/services';
import { environment } from 'src/environments/environment';

import { Router, RouterModule } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { Observable, Subscription, from, map } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';



interface MenuList {
  name: string;
  url: string;
  isCollapsed?: boolean;
  children?: MenuList[];
  access_tokens?: string[];
  user_url_key?: string;
}

@Component({
  selector: 'user-dashboard-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule, RouterModule, MatButtonModule]
})
export class MainComponent implements OnInit {




  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();


  public opened = signal<boolean>(false)
  public userImageUrl: string;
  public displayName: string;
  public displayEmail: string;
  private userInfo: any;

  private subs: { [key: string]: Subscription } = {};

  public menuData: MenuList[] = [
    {
      name: 'Transcribe',
      url: '/apps/transcribe',
      isCollapsed: true,
      children: undefined,
      access_tokens: environment.transcribe_access,
    },
    {
      name: 'Admin',
      url: '/apps/admin',
      isCollapsed: true,
      children: undefined,
      access_tokens: environment.admin,
    },
    {
      name: 'Viewer',
      url: '/apps/viewer',
      isCollapsed: true,
      children: undefined,
      access_tokens: environment.admin,
    },

  ];

  public menuData$: Observable<MenuList[]>
  public is_authenticated_user: Observable<boolean>

  constructor(public authService: FirebaseAuthService,
    private router: Router, private viewCommunication: ViewCommunicationService,


  ) {
    this.is_authenticated_user = authService.checkAuthentication().pipe(map(user => !!user))
    const claims$ = from(getAuth().currentUser.getIdTokenResult())
    this.opened.set(false);
    this.menuData$ = claims$.pipe(
      map(({ claims }) => this.menuData
        .filter(item => !item.access_tokens || item.access_tokens.some(access_token => claims[access_token]))
        .map(x => {
          x.url = x.user_url_key ? x.url + '/' + (claims[x?.user_url_key] || '') : x.url;
          return x;
        })
      ))

  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;


  ngOnInit() {
    this.getUserInfo();
  }

  ngDoCheck(): void {

    if (!(this.dataSource.data && this.dataSource.data.length > 1)) {
      this.menuData[0].children = this.viewCommunication.rawMap.transcribe_main_nav;
      this.menuData[1].children = this.viewCommunication.rawMap.admin_main_nav;
    }
  }

  public toggleSidebar(): void {
    this.opened.update(last => !last);
  }

  public getUserInfo(): void {
    const authChages$ = this.authService.checkAuthentication()
    this.subs['authState'] = authChages$.subscribe(user => {
      this.userInfo = user;
      if (getAuth().currentUser) {
        this.userImageUrl = user.photoURL;
        this.displayName = user.displayName;
        this.displayEmail = user.email;
        user.getIdTokenResult().then(({ claims }) => this.userInfo.claims = claims)

      }
      else {
        this.userImageUrl = '';
        this.userInfo.claims = null;
      }
    });


  }

  public signOut(): void {
    this.authService.signOut().then(v => this.router.navigate(['/home']));
  }

  public hasAccessToMenuItem(accessNeeded: any) {
    if (this.userInfo && this.userInfo.claims) {
      return accessNeeded.some(t => this.userInfo.claims[t] !== undefined)
    }
    return false;
  }
}