import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FirebaseAuthService } from "src/app/modules/shared/services/fbauth.service";

@Component({
    selector: 'app-waiting-access',
    templateUrl: './waiting-access.component.html',
    styleUrls: ['./waiting-access.component.scss'],
    standalone: true,
})
export class WaitingAccessComponent implements OnInit {
    constructor(
        public auth: FirebaseAuthService,
        private router: Router
    ) { }

    ngOnInit() { }

    public signOut() {
        this.auth.signOut().then(v => this.router.navigate(['/home']));
    }
}