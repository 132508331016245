import { AfterViewInit, Component, OnInit } from '@angular/core';
import { loadMonaco } from './admin-portal/services/moncao-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
  constructor(snackBar: MatSnackBar) {
    window['MAT_SNACK_BAR'] = snackBar;
  }
  ngAfterViewInit() {
    

  }
}


