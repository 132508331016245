import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp } from 'firebase/app';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoggedInDashboardModule } from './modules/logged-in-dashboard/logged-in-dashboard.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { initializeFirestore } from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { getAuth, indexedDBLocalPersistence, setPersistence } from 'firebase/auth';
import { PACS_ADAPTER } from './modules/shared/services/dicom/pacs-adaptors/pacs-adaptor.interface';
import { OrthancAdaptorService } from './modules/shared/services/dicom/pacs-adaptors/orthanc-adaptor.service';
import { GoogleAdaptorService } from './modules/shared/services/dicom/pacs-adaptors/google-adaptor.service';
// import { UploaderModule, ViewerModule } from 'src/realExports';

// import {PacsTokenService} from './modules/viewer/services/pacs-token.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  exports: [

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    AppRoutingModule,

    TranslateModule
      .forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: 'en'
      }),
      
  ],

  //     .concat(environment['importViewer'] ? [ViewerModule, UploaderModule] : []),
  providers: [

    { provide: APP_INITIALIZER, multi: true, useFactory: () => initApp },
    { provide: Window, useValue: window },
    // {
    //   provide: PACS_ADAPTER, useClass : environment['useOrhancAdaptor'] ? OrthancAdaptorService : GoogleAdaptorService
    // },
    // { provide: ORIGIN, useValue: 'https://bedwax.ebdev' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

async function initApp() {

  const firbase_app = initializeApp(environment.firebaseConfig);

  initializeFirestore(firbase_app, { ignoreUndefinedProperties: true });
  await setPersistence(getAuth(),indexedDBLocalPersistence);
  
}