import { NgModule, inject } from '@angular/core';
import { CanActivateFn, CanDeactivateFn, Router, RouterModule, Routes, UrlTree } from '@angular/router';
import { MainComponent as AppsMainComponent, WaitingAccessComponent } from './modules/logged-in-dashboard/components';
import { ErrorPagesComponent } from './modules/shared/components';

import { getAuth } from 'firebase/auth';
import { ViewerWrapperComponent } from './modules/dcm-viewer/components/viewer-wrapper/viewer-wrapper.component';
import { authStatusPromiseFacotry } from './utils';

let post_auth_url: string = null;


const authorizedGuard: CanDeactivateFn<any> = async (component, route, state): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const user = getAuth().currentUser;
  if (user && await user.getIdTokenResult()) {
    const { claims } = await user.getIdTokenResult()
    if (!claims['transcribeAccess']) {
      return router.createUrlTree(['portal']);
    }
    else {
      return true;
    }
  }
  else {
    post_auth_url = state.url;
    return router.createUrlTree(['home'], { queryParams: { login: 1 } });
  }
}


const athenticatedUsersGuard: CanActivateFn = (route, state) => authStatusPromiseFacotry().then(r => !!r);
const athenticatedPortalUsersGuard: CanActivateFn = (route, state) => {

  return authStatusPromiseFacotry().then(user => user.getIdTokenResult())
    .then(({ claims }) => {
      // console.log(claims)
      if (claims['transcribeAccess']) {
        return true;
      }
      if (claims['customer_access']) {
        return true;
      }
      return false;
    });

}




// const adminAccess = () => pipe(customClaims, map(claims => claims.admin === undefined ? ['not_found'] : true));

// const appAccess = () => pipe(customClaims, map(claims => claims.transcribeAccess === undefined && claims.customer_access === undefined ? ['access-request'] : true));





const routes: Routes = [

  {
    path: 'apps', component: AppsMainComponent,
    canActivate: [
      authorizedGuard
    ], children: [
      ...[
        {
          path: 'transcribe', canActivate: [athenticatedUsersGuard], loadChildren: () => import('./modules/transcribe/transcribe.module').then(m => m.TranscribeModule)
        },
        {
          path: 'viewer', canActivate: [athenticatedUsersGuard], children: [
            { path: ':sessionId', component: ViewerWrapperComponent }
          ]
        },
        // {
        //   path: 'admin', canActivate: [], /* data: { authGuardPipe: adminAccess }, */ loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        // },
        {
          path: 'admin',
          canActivate: [
            athenticatedUsersGuard,],
          loadChildren: () => import('./admin-portal/admin-routes').then(m => m.ADMIN_ROUTES),
        }
      ],
      //   environment.importUploader ? { path: 'uploader', canActivate: [athenticatedUsersGuard = ], data: { authGuardPipe: appAccess }, loadChildren: () => UploaderModule, } : undefined,
      // ],

    ].filter(p => p !== undefined)
    ,

  },
  {
    path: 'portal',
    canActivate: [
      athenticatedUsersGuard,
      athenticatedPortalUsersGuard],
    loadChildren: () => import('./client-portal/client-portal-routes').then(m => m.CLIENT_PORTAL_ROUTES),
  },
  { path: 'access-request', component: WaitingAccessComponent, canActivate: [athenticatedUsersGuard] },
  { path: 'not_found/:code', component: ErrorPagesComponent },
  { path: 'not_found', component: ErrorPagesComponent },
  { path: '', loadChildren: () => import('./modules/main-marketing/marketing.module').then(m => m.MarketingModule) },
  // { path: '**', redirectTo: 'not_found' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' /* , enableTracing:true  */ })],
  exports: [RouterModule]
})
export class AppRoutingModule { }






